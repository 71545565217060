const NODE_ENV = 'prod';
// const NODE_ENV = 'uat';
// const NODE_ENV = 'preProd';
// const NODE_ENV = 'dev';

const DEV_BASE_URL = 'https://etlinode.salesdrive.app/';
const BM_BASE_URL = 'https://etli-featurewise-node.salesdrive.app/';
const UAT_BASE_URL = 'https://apis-uat.myzindagi.edelweisslife.in/'; 
const PROD_BASE_URL = 'https://etliwebprodnodenew.salesdrive.app/';


// const PROD_BASE_URL = 'https://apis-preprod.myzindagi.edelweisslife.in/';
// const PROD_BASE_URL = 'https://etlinode-prod-poc.salesdrive.app/';

// // PRE-PROD
// const PRE_PROD_URL = "apis-preprod.myzindagi.edelweisslife.in";
// const PRE_PROD_BASE_URL = "base-preprod-local.salesdrive.app";

// // NEW PROD (26 Sep 2024)
// const PROD_URL = "etlinode-prod-poc.salesdrive.app";
// const PROD_BASE_URL = "etlibasenode-preprod-poc.salesdrive.app";

const _config = {
  baseURL: NODE_ENV === 'dev' ? DEV_BASE_URL : NODE_ENV === 'uat' ? UAT_BASE_URL : PROD_BASE_URL,
  // baseURL: NODE_ENV === 'development' ? DEV_BASE_URL : PROD_BASE_URL,
  // baseURL: UAT_BASE_URL,
  bmOnboardingURL: NODE_ENV === 'dev' ? BM_BASE_URL : NODE_ENV === 'uat' ? UAT_BASE_URL : PROD_BASE_URL,
  auth: 'auth/',
  secure: 'secure/',
  NODE_ENV: NODE_ENV
}

export default _config;